.register-page {
  .form-section.current {
    display: inherit;
  }
  .form-section {

    display: none;
  }
  .parsley-required {
    color: red;
  }
  .parsley-equalto {
    color: red;
  }
  .parsley-type {
    color: red;
  }
}