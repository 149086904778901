/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Apr 16, 2016, 2:54:22 PM
    Author     : Waleed
*/


.login-page {

    .alert .alert-danger ul li
    {

        display:block;
    }


}

